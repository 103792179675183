import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.3.1-canary.4_@babel+core@7.26.10_@opentelemetry+api@1.9.0_babel-plugin-react-co_b08c03c3ce7fb20a2923a52f96ddced6/node_modules/next/dist/client/components/client-page.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.3.1-canary.4_@babel+core@7.26.10_@opentelemetry+api@1.9.0_babel-plugin-react-co_b08c03c3ce7fb20a2923a52f96ddced6/node_modules/next/dist/client/components/client-segment.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.3.1-canary.4_@babel+core@7.26.10_@opentelemetry+api@1.9.0_babel-plugin-react-co_b08c03c3ce7fb20a2923a52f96ddced6/node_modules/next/dist/client/components/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.3.1-canary.4_@babel+core@7.26.10_@opentelemetry+api@1.9.0_babel-plugin-react-co_b08c03c3ce7fb20a2923a52f96ddced6/node_modules/next/dist/client/components/http-access-fallback/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.3.1-canary.4_@babel+core@7.26.10_@opentelemetry+api@1.9.0_babel-plugin-react-co_b08c03c3ce7fb20a2923a52f96ddced6/node_modules/next/dist/client/components/layout-router.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.3.1-canary.4_@babel+core@7.26.10_@opentelemetry+api@1.9.0_babel-plugin-react-co_b08c03c3ce7fb20a2923a52f96ddced6/node_modules/next/dist/client/components/metadata/async-metadata.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.3.1-canary.4_@babel+core@7.26.10_@opentelemetry+api@1.9.0_babel-plugin-react-co_b08c03c3ce7fb20a2923a52f96ddced6/node_modules/next/dist/client/components/metadata/metadata-boundary.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.3.1-canary.4_@babel+core@7.26.10_@opentelemetry+api@1.9.0_babel-plugin-react-co_b08c03c3ce7fb20a2923a52f96ddced6/node_modules/next/dist/client/components/render-from-template-context.js");
